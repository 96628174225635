/* import faq from './faq.svg' */
/* import doc from './doc.svg' */
import "./footer-mobile.scss";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import LangSwitcher from "../../aside/lang-switcher/lang-switcher";

function FooterMobile() {
  const { t } = useTranslation();
  return (
    <div className="mobile-footer">
      <div className="pages">
        {/* <Link to="/products/all" className='pages-item'>
                    <img src="https://upload.wikimedia.org/wikipedia/commons/e/ec/Circle-icons-mail.svg" alt="" />
                    <span>{t('footer.list')}</span>
                </Link> */}
      </div>
      <LangSwitcher />

      <div className="mobile-contacts">
        {/* <p>{t('footer.time')}</p> */}
        <a href="tel:8(747)-557-74-64" className="phone">
          {t("footer.phone")}
        </a>
        {/* <p>{t('footer.address_city')}</p> */}
        {/* <a href='https://go.2gis.com/g622b' target="_blank" rel="noreferrer">{t('footer.address')}</a> */}
      </div>
      <div className="mobile-icon-holder">
        <a
          className="penumbra-telegram"
          href="https://t.me/+77475577464"
          aria-label="Telegram Link"
          target="_blank"
          rel="noreferrer"
        >
          {" "}
        </a>
        <a
          className="penumbra-instagram"
          href="https://www.instagram.com/penumbra.rental/"
          aria-label="Instagram Link"
          target="_blank"
          rel="noreferrer"
        >
          {" "}
        </a>
        <a
          className="penumbra-whatsapp"
          href="https://wa.me/+77475577464"
          aria-label="Whatsapp Link"
          target="_blank"
          rel="noreferrer"
        >
          {" "}
        </a>
      </div>
    </div>
  );
}

export default FooterMobile;
