import React, { useState } from "react";
import { ProductService } from '../../../services/products.service'; // Import your service
import { Spinner } from "../../common/spinner/spinner"; // Import your spinner

const productService = new ProductService(); // Initialize your service

const EquipmentRequestPage = () => {
    type ResponseType = {
        requests: {
            name: string;
            equipment: string;
        }[];
    };

    const [response, setResponse] = useState<ResponseType | null>(null);
    const [equipmentRequest, setEquipmentRequest] = useState("");
    const [isLoading, setIsLoading] = useState(false); // Initialize loading state
    const [error, setError] = useState(null); // Initialize error state
    const [warmMessages, setWarmMessages] = useState<{ [key: number]: string }>({});

    const rentalPhoneNumbers = {
        "BestRental": "77052878380",
        "Best Rental": "77052878380",
        "Best": "77052878380",
        "Yume Rental": "77475577464",
        "Yume": "77475577464",
        "YumeRental": "77475577464",
        "Gogo": "77475577464",
    };

    const handleWarmMessage = async (rentalName: string, equipment: string) => {
        const phoneNumber = rentalPhoneNumbers[rentalName];
        if (!phoneNumber) {
            console.error(`Phone number not found for rental: ${rentalName}`);
            return;
        }

        productService.messageRequest(equipment)
            .then(responseData => {
                const parsedData = JSON.parse(responseData);
                setWarmMessages(prevMessages => ({ ...prevMessages, [phoneNumber]: parsedData.message }));

                // Forming the special link
                const encodedMessage = encodeURIComponent(parsedData.message);
                const whatsappLink = `https://api.whatsapp.com/send/?phone=${phoneNumber}&text=${encodedMessage}`;

                // Opening the link
                window.open(whatsappLink);
            })
            .catch(error => {
                console.error("There was an error!", error);
            });
    };

    /* const handleWarmMessage = async (index: number, equipment: string) => {
     *     productService.messageRequest(equipment)
     *         .then(responseData => {
     *             const parsedData = JSON.parse(responseData);
     *             setWarmMessages(prevMessages => ({ ...prevMessages, [index]: parsedData.message }));

     *             // Forming the special link
     *             const encodedMessage = encodeURIComponent(parsedData.message);
     *             const whatsappLink = `https://api.whatsapp.com/send/?phone=${index}&text=${encodedMessage}`;

     *             // Opening the link
     *             window.open(whatsappLink);
     *         })
     *         .catch(error => {
     *             console.error("There was an error!", error);
     *         });
     * }; */



    const handleSubmit = async (event) => {
        event.preventDefault();

        setIsLoading(true); // Set loading state to true when the request starts
        setError(null); // Clear any existing errors when a new request is made
        setResponse(null); // Clear any existing responses when a new request is made

        productService.equipmentRequest(equipmentRequest)
            .then(responseData => {
                const parsedData = JSON.parse(responseData); // Parse the JSON string into a JavaScript object
                setResponse(parsedData); // Update the response state with the parsed data
            })
            .catch(error => {
                console.error("There was an error!", error);
                setError(error.message); // Set error state if an error occurs
            })
            .finally(() => {
                setIsLoading(false); // Set loading state to false when the request finishes, either successfully or with an error
            });
    };


    return (
        <div>
            <h2>Equipment Request</h2>
            <form onSubmit={handleSubmit}>
                <label>
                    <textarea
                        value={equipmentRequest}
                        onChange={(e) => setEquipmentRequest(e.target.value)}
                    />
                </label>
                <button type="submit" disabled={isLoading}>Submit</button> {/* Optionally disable the button while loading */}
            </form>
            {isLoading && <Spinner color="#fff" />} {/* Show the spinner while loading */}
            {error && <div className="error-message">{error}</div>} {/* Show error message when an error occurs */}
            <div style={{ display: "flex", margin: "10px", gap: "30px", paddingTop: "50px" }}>
                {response && response.requests && response.requests.map((request, index) => (
                    <div key={index}>
                        <h3>{request.name}</h3>
                        <p style={{ width: "100px" }}>Equipment: {request.equipment}</p>
                        <button onClick={() => handleWarmMessage(request.name, request.equipment)}>
                            Whatsapp
                        </button>
                        {warmMessages[index] && (
                            <p>{warmMessages[index]}</p>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );

};

export default EquipmentRequestPage;
