import "./aside.scss";
/* import icon from './icon.svg' */
import { CategoriesService } from "../../services/category.service";
import { useStore, useStoreWithInitializer } from "../../store/storeHooks";
import { store } from "../../store/store";
import {
  changeCategory,
  loadCategories,
  startLoadingCategories,
} from "./aside.slice";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useLocationChange } from "../../utils/locationChange";
import { localeString } from "../../utils/localeString";
import { useTranslation } from "react-i18next";
import { Spinner } from "../common/spinner/spinner";
import getImage from "../../utils/image";

/* import phoneIcon from "./phone.svg"; */

import Modal from "react-bootstrap/Modal";
/* import callData from './incoming-call.json'; */

function PhoneModal(props: any) {
  const { t } = useTranslation();
  const defaultOptions = {
    loop: true,
    autoplay: true,
    renderer: "svg",
  };
  return (
    <Modal
      {...props}
      size="l"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="phone-modal">
        <h1>phone modal</h1>
        <div
          className="text"
          dangerouslySetInnerHTML={{ __html: t("have_questions") }}
        ></div>
        <div className="number">8 747 557 74 64</div>
        <div className="address">{t("footer.address")}</div>
        <div className="social-holder">
          <a
            className="penumbra-telegram"
            href="https://t.me/+77475577464"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
          </a>
          <a
            className="penumbra-instagram"
            href="https://www.instagram.com/penumbra.rental/"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
          </a>
          <a
            className="penumbra-whatsapp"
            href="https://wa.me/+77475577464"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
          </a>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export function Aside() {
  const { t } = useTranslation();
  const asideRef = useRef(null);
  const [phoneModal, setPhoneModal] = useState(false);

  const navigate = useNavigate();
  const scrollToRef = (ref: any) =>
    ref.current?.scroll({
      top: 0,
      behavior: "smooth",
    });

  useEffect(() => {
    scrollToRef(asideRef);
  }, []);

  useLocationChange((location: any) => {
    scrollToRef(asideRef);
  });

  const { categories, selectedCategory } = useStore(({ category }) => category);

  /* const listItems: JSX.Element[] = categories.map((category, key: number) =>
   *   <Link to={category.slug} onClick={() => store.dispatch(changeCategory(category.slug))} key={key} className={`menu-item animation-opacity ${selectedCategory === category.slug ? "active" : ""}`}>
   *     <span className="overflow ">{t(localeString(category, 'name'))} </span>
   *   </Link>
   * );
   */
  const sortOrder = [
    "cameras",
    "lenses",
    "lighting",
    "support",
    "lens-accesoires",
    "monitors",
    "grip",
  ];

  const listItems: JSX.Element[] = categories
    .filter(
      (category) =>
        category.slug !== "transport" && category.slug !== "personnel"
    )
    .sort((a, b) => {
      let indexA = sortOrder.indexOf(a.slug);
      let indexB = sortOrder.indexOf(b.slug);

      if (indexA === -1) indexA = Number.MAX_VALUE;
      if (indexB === -1) indexB = Number.MAX_VALUE;

      return indexA - indexB;
    })
    .map((category, key: number) => (
      <Link
        to={category.slug}
        onClick={() => store.dispatch(changeCategory(category.slug))}
        key={key}
        className={`menu-item animation-opacity ${
          selectedCategory === category.slug ? "active" : ""
        }`}
      >
        <span className="white-space">
          {t(localeString(category, "name"))}{" "}
        </span>
      </Link>
    ));

  return (
    <aside ref={asideRef}>
      <img
        height="22px"
        src="/Logo.png"
        onClick={() => {
          navigate("/");
          store.dispatch(changeCategory(""));
        }}
        className="logo"
        alt="logo"
        draggable="false"
      />
      {categories.length === 0 ? (
        <div className="spinner-holder">
          <Spinner color="#000" />
        </div>
      ) : (
        <div className="menu">{listItems}</div>
      )}

      {/* PHONE MODAL */}
      {/* <div onClick={() => setPhoneModal(true)} className='request-phone'>
                <span>{t('request_call')}</span>
            </div> */}
      {/* <PhoneModal
                show={phoneModal}
                onHide={() => setPhoneModal(false)}
            /> */}
    </aside>
  );
}
