import "./footer.scss";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function Footer() {
  const { t } = useTranslation();
  return (
    <footer className="breakpoint">
      <div className="contacts">
        {/* <p>{t('footer.time')}</p> */}
        <a href="tel:8(747)-557-74-64" className="phone">
          {t("footer.phone")}
        </a>
        {/* <p>{t('footer.address_city')}</p> */}
        {/* <a href='https://go.2gis.com/g622b' target="_blank" rel="noreferrer">{t('footer.address')}</a> */}
      </div>
      <div className="social">
        <div className="icon-holder">
          <a
            className="penumbra-telegram"
            href="https://t.me/+77475577464"
            target="_blank"
            rel="noreferrer"
            aria-label="Telegram"
          >
            {" "}
          </a>
          <a
            className="penumbra-instagram"
            href="https://www.instagram.com/penumbra.rental/"
            target="_blank"
            rel="noreferrer"
            aria-label="Instagram"
          >
            {" "}
          </a>
          <a
            className="penumbra-whatsapp"
            href="https://wa.me/+77475577464"
            target="_blank"
            rel="noreferrer"
            aria-label="WhatsApp"
          >
            {" "}
          </a>
        </div>

        {/* <Link to="/products/all">{t('footer.list')}</Link> */}
      </div>
    </footer>
  );
}

export default Footer;
